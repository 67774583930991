// plugins/AptReservationsPlugin.js
import device from 'current-device';

  const state = {
    aptReservations: {
      ArrivalDate: '',
      DepartureDate: '',
      DepartureTime: '',

      pickupDate: '',
      pickupTime: '',
      returnDate: '',
      returnTime: '',
      
      origin: '',
      destination: '',
      serviceType: 1,
      isActivityTransfer: true,
      isDestinationAirport: 0,
      isOriginAirport: 0,
      isArrival: true,
      isDeparture: false,
      arrivalAirline: '',
      arrivalFlightNumber: '',
      arrivalFlightDate: '',
      arrivalFlightTime: '',
      departureAirline: '',
      departureFlightNumber: '',
      departureFlightDate: '',
      departureFlightTime: '',
      adults: 2,
      kids: 0,
      infants: 0,
      vehicles: {
        vehiclePerCompany_id: 0,
        make: '',
        model: '',
        totalAmount: 0,
        Quantity: 0,
      },
      addons: [],
      client: {
        fname: '',
        lname: '',
        email: '',
        phone: '',
      },
      booking_id: '',
      client_id: '',
      status: 0,
      subtotal: 0,
      tax: 0,
      tax_amount: 0,
      total: 0,
      isPrepay: false,
      prepay_amount: 0,
      special_requests: '',
      promoCode: '',
      repCode: '',
      sendEmail: true,

      deviceInfo: {
        type: '',  // mobile, desktop, tablet
        orientation: '',  // portrait, landscape
        browserData: {},
      },
      utmParams: {},
      PDFlink: '',
      ally_booking_id: '',
      currency: 'USD',
      // ... Cualquier otro campo necesario
      // changexx
    },
  };
  
  const mutations = {
    SET_CURRENCY(state, currency) {
      state.aptReservations.currency = currency;
    },
    SET_APT_RESERVATIONS(state, payload) {
      state.aptReservations = { ...state.aptReservations, ...payload };
    },
    SET_PDF_LINK(state, link) {
      state.PDFlink = link;
    },
    CLEAR_APT_RESERVATIONS(state) {
      state.aptReservations = {
        ArrivalDate: '',
        DepartureDate: '',
        DepartureTime: '',

        pickupDate: '',
        pickupTime: '',
        returnDate: '',
        returnTime: '',

        origin: '',
        destination: '',
        serviceType: 1,
        isActivityTransfer: true,
        isDestinationAirport: 0,
        isOriginAirport: 0,
        isArrival: true,
        isDeparture: false,
        arrivalAirline: '',
        arrivalFlightNumber: '',
        arrivalFlightDate: '',
        arrivalFlightTime: '',
        departureAirline: '',
        departureFlightNumber: '',
        departureFlightDate: '',
        departureFlightTime: '',
        adults: 2,
        kids: 0,
        infants: 0,
        vehicles: {
          vehiclePerCompany_id: 0,
          make: '',
          model: '',
          totalAmount: 0,
          Quantity: 0,
        },
        addons: [],
        client: {
          fname: '',
          lname: '',
          email: '',
          phone: '',
        },
        booking_id: '',
        client_id: '',
        status: 0,
        subtotal: 0,
        tax: 0,
        tax_amount: 0,
        total: 0,
        isPrepay: false,
        prepay_amount: 0,
        prepay_due: 0,
        special_requests: '',
        promoCode: '',
        repCode: '',
        sendEmail: true,

        deviceInfo: {
          type: '',  // mobile, desktop, tablet
          orientation: '',  // portrait, landscape
          browserData: {},
        },
        utmParams: {},
        PDFlink: '',
        ally_booking_id: '',
        currency: 'USD',
        // ... Cualquier otro campo necesario
      };
    },

    SET_DEVICE_INFO(state, payload) {
      state.aptReservations.deviceInfo = payload;
    },
    SET_UMT_PARAMS(state, utmData) {
      state.aptReservations.utmParams = utmData;
    },
    

    SET_IS_ARRIVAL(state) {
      state.aptReservations.isArrival = true
      state.aptReservations.isDeparture = false
    },

    SET_IS_DEPARTURE(state) {
      state.aptReservations.isArrival = false
      state.aptReservations.isDeparture = true
      state.aptReservations.DepartureTime = '12:00'
    },

    SET_IS_ROUNDTRIP(state) {
      state.aptReservations.isArrival = true
      state.aptReservations.isDeparture = true
    },
    // ... otras mutaciones que puedas necesitar
  };
  
  const actions = {

    updateCurrency({ commit }, currency) {
      commit('SET_CURRENCY', currency);
    },
    detectDeviceInfo({ commit }) {
      // console.log("Detecting device info...");
      device.onChangeOrientation(() => {
        // console.log("Orientation change detected.");
        commit('SET_DEVICE_INFO', {
          type: device.type,
          orientation: device.orientation,
          browserData: {
            userAgent: navigator.userAgent,
            language: navigator.language,
            platform: navigator.platform,
            screenWidth: window.innerWidth,
            screenHeight: window.innerHeight,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
        });
      });
  
      // Llama inicialmente sin esperar un cambio de orientación
      commit('SET_DEVICE_INFO', {
        type: device.type,
        orientation: device.orientation,
        browserData: {
          userAgent: navigator.userAgent,
          language: navigator.language,
          platform: navigator.platform,
          screenWidth: window.innerWidth,
          screenHeight: window.innerHeight,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      });
    },
    captureUTMParameters({ commit }) {
      // console.log("Capturing UTM parameters...");
      const urlParams = new URLSearchParams(window.location.search);
      const utmData = {
        utm_source: urlParams.get('utm_source'),
        utm_medium: urlParams.get('utm_medium'),
        utm_campaign: urlParams.get('utm_campaign'),
      };
      // console.log(utmData);
      commit('SET_UMT_PARAMS', utmData);
    },

    updateAptReservations({ commit }, payload) {
      commit('SET_APT_RESERVATIONS', payload);
    },
    clearAptReservations({ commit }) {
      commit('CLEAR_APT_RESERVATIONS');
    },

    async create_aptReservations(_, {dataToUpdate, status}) {
      const API_URL = `${process.env.VUE_APP_BASEURL}public/apt-reservations`;
      try {
          const payload = {
              bookingObject: JSON.stringify(dataToUpdate.bookingObject)
          };
  
          const res = await fetch(API_URL, {
              method: 'POST',
              headers: {
                  'Accept': 'application/json',
                  'Reservation-Status': status,
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(payload)
          });
  
          const response = await res.json();
          console.log('Response from server:', response);
          return response;
      } catch (error) {
          console.error('Error during query:', error);
          throw new Error(error);
      }
  },

  async validateBookingTime(_, dataToUpdate) {
    const API_URL = `${process.env.VUE_APP_BASEURL}public/validateBookingTime`;
    try {
        const payload = {
            bookingObject: JSON.stringify(dataToUpdate.bookingObject)
        };

        const res = await fetch(API_URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload)
        });

        const response = await res.json();
        console.log('Response from server:', response);
        return response;
    } catch (error) {
        console.error('Error during query:', error);
        throw new Error(error);
    }
},

  async create_ally_aptReservations(_, {dataToUpdate, status}) {
    const API_URL = `${process.env.VUE_APP_BASEURL}public/ally-apt-reservations`;
    // console.log('sent to ally');
    try {
        const payload = {
            bookingObject: JSON.stringify(dataToUpdate.bookingObject)
        };

        const res = await fetch(API_URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Reservation-Status': status,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload)
        });

        const response = await res.json();
        console.log('Response from server:', response);
        return response;
    } catch (error) {
        console.error('Error during query:', error);
        throw new Error(error);
    }
},

    async initiate_StripePayment(_, {dataToUpdate, companyID}) {
      try {
        const API_URL = `${process.env.VUE_APP_BASEURL}payment/initiate`;
        // console.log('JSON.stringify(dataToUpdate)', JSON.stringify(dataToUpdate));
        // console.log('dataToUpdate: ', dataToUpdate);
        const res = await fetch(API_URL, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'User-Company': companyID,
            // 'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json', // Indica que los datos son JSON
          },
          body: JSON.stringify(dataToUpdate) // Convierte los datos a una cadena JSON
        });
    
        const response = await res.json();
        console.log('Response from server:', response);
    
        if ('errors' in response) {
          return response.errors;
        }
    
        return response;
      } catch (error) {
        console.error('Error during query:', error);
        throw new Error(error);
      }
    },

    async create_Client(_, dataToUpdate) {
      try {
        const API_URL = `${process.env.VUE_APP_BASEURL}public/clients`;
        // console.log('API_URL', API_URL);
        const res = await fetch(API_URL, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json', // Indica que los datos son JSON
          },
          body: JSON.stringify(dataToUpdate) // Convierte los datos a una cadena JSON
        });
    
        const response = await res.json();
        console.log('Response from server:', response);
    
        if ('errors' in response) {
          return response.errors;
        }
    
        return response;
      } catch (error) {
        console.error('Error during query:', error);
        throw new Error(error);
      }
    },

    async getPublicAptReservationsInfo(_,aptResID) {
      try {
        const API_URL = `${process.env.VUE_APP_BASEURL}public/apt-reservations/${aptResID}`;
    
        const res = await fetch(API_URL, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        });
    
        const response = await res.json();
    
        console.log('Response from server:', response);
    
        if ('errors' in response) {
          return response.errors;
        }
    
        return response;
      } catch (error) {
        console.error('Error during query:', error);
        throw new Error(error);
      }
    },
    async get_all_ZonesPerCompany(_, userCompany) {
      try {

        const API_URL = `${process.env.VUE_APP_BASEURL}public/search-company-zones`;

        // Create headers object with common headers
        const headers = {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'User-Company': userCompany
        };
    
        const res = await fetch(API_URL, {
          method: 'GET',
          headers: headers
        });
    
        const response = await res.json();
    
        // console.log('Response from server:', response);
    
        if ('errors' in response) {
          return response.errors;
        }
  
        return response;
      } catch (error) {
        // console.error('Error during query:', error);
        throw new Error(error);
      }
    },
    async get_all_Fleet(_, userCompany) {
      try {

        const API_URL = `${process.env.VUE_APP_BASEURL}public/search-company-vehicles`;

        // Create headers object with common headers
        const headers = {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'User-Company': userCompany
        };
    
        const res = await fetch(API_URL, {
          method: 'GET',
          headers: headers
        });
    
        const response = await res.json();
    
        // console.log('Response from server:', response);
    
        if ('errors' in response) {
          return response.errors;
        }
  
        return response;
      } catch (error) {
        // console.error('Error during query:', error);
        throw new Error(error);
      }
    },
    async searchAptRates(_,{arrivalDate, departureDate, companyId, zonePerCompanyId, isArrival, isDeparture, vehiclePerCompany_ids, originZone, destinationZone, serviceType, allyCode, destinationID, PAX}) {
      try {
        const API_URL = `${process.env.VUE_APP_BASEURL}public/search-company-transportation-rates`;

        // Convertir el arreglo a una cadena JSON
        const vehicleIDSJson = JSON.stringify(vehiclePerCompany_ids);
    
        const res = await fetch(API_URL, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Rate-Arrival': arrivalDate,
            'Rate-Departure': departureDate,
            'Rate-Company': companyId,
            'Rate-Zone': zonePerCompanyId,
            'isArrival': isArrival,
            'isDeparture': isDeparture,
            'fromZone': originZone,
            'toZone': destinationZone,
            'serviceType': serviceType,
            'allyCode': allyCode,
            'destinationID': destinationID,
            'PAX': PAX,
            'vehicleIDS': vehicleIDSJson
          },
        });
    
        const response = await res.json();
    
        // console.log('Response from server:', response);
    
        if ('errors' in response) {
          return response.errors;
        }
    
        return response;
      } catch (error) {
        console.error('Error during query:', error);
        throw new Error(error);
      }
    },
    async get_all_TransportationAddons(_, userCompany) {
      try {

        const API_URL = `${process.env.VUE_APP_BASEURL}public/search-company-transportation-addons`;

        // Create headers object with common headers
        const headers = {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'User-Company': userCompany
        };
    
        const res = await fetch(API_URL, {
          method: 'GET',
          headers: headers
        });
    
        const response = await res.json();
    
        // console.log('Response from server:', response);
    
        if ('errors' in response) {
          return response.errors;
        }
  
        return response;
      } catch (error) {
        // console.error('Error during query:', error);
        throw new Error(error);
      }
    },
    async getCommercialAlliesInfo(_, ally_company_id) {
      try {
        const API_URL = `${process.env.VUE_APP_BASEURL}find-commercial-allies/${ally_company_id}`;
    
        const res = await fetch(API_URL, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        });
    
        const response = await res.json();
    
        // console.log('Response from server:', response);
    
        if ('errors' in response) {
          return response.errors;
        }
    
        return response;
      } catch (error) {
        console.error('Error during query:', error);
        throw new Error(error);
      }
    },
    async findCommercialAlliesInfo(_, allyID) {
      try {
        const API_URL = `${process.env.VUE_APP_BASEURL}public/find-commercial-ally/${allyID}`;
    
        const res = await fetch(API_URL, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        });
    
        const response = await res.json();
    
        // console.log('Response from server:', response);
    
        if ('errors' in response) {
          return response.errors;
        }
    
        return response;
      } catch (error) {
        console.error('Error during query:', error);
        throw new Error(error);
      }
    },
    async getRepresentativeInfo(_, {userCompany, repID}) {
      try {
        const API_URL = `${process.env.VUE_APP_BASEURL}public/find-sales-representative/${repID}`;
    
        const res = await fetch(API_URL, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'User-Company': userCompany
          },
        });
    
        const response = await res.json();
    
        console.log('Response from server:', response);
    
        if ('errors' in response) {
          return response.errors;
        }
    
        return response;
      } catch (error) {
        console.error('Error during query:', error);
        throw new Error(error);
      }
    },
    async searchAllyZoneRates(_,allyCode) {
      try {
        const API_URL = `${process.env.VUE_APP_BASEURL}find-public-ally-rates/${allyCode}`;

    
        const res = await fetch(API_URL, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        });
    
        const response = await res.json();
    
        // console.log('Response from server:', response);
    
        if ('errors' in response) {
          return response.errors;
        }
    
        return response;
      } catch (error) {
        console.error('Error during query:', error);
        throw new Error(error);
      }
    },
    async get_all_TranspDest() {
      try {

        const API_URL = `${process.env.VUE_APP_BASEURL}public/transportation_destinations`;
        // console.log('userType: ', userType)

        // Create headers object with common headers
        const headers = {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        };
    
        const res = await fetch(API_URL, {
          method: 'GET',
          headers: headers
        });
    
        const response = await res.json();
    
        // console.log('Response from server:', response);
    
        if ('errors' in response) {
          return response.errors;
        }
  
        return response;
      } catch (error) {
        // console.error('Error during query:', error);
        throw new Error(error);
      }
    },
    async getTranspDestInfo(_,destinationID) {
      try {
        // console.log('destinationID', destinationID);
        const API_URL = `${process.env.VUE_APP_BASEURL}public/transportation_destinations/${destinationID}`;
    
        const res = await fetch(API_URL, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        });
    
        const response = await res.json();
    
        // console.log('Response from server:', response);
    
        if ('errors' in response) {
          return response.errors;
        }
    
        return response;
      } catch (error) {
        console.error('Error during query:', error);
        throw new Error(error);
      }
    },
    
    // ... todas tus acciones aquí, asegurándote de que cada una esté correctamente cerrada
  };
  
  const getters = {
    getCurrency(state) {
      return state.aptReservations.currency;
    },
    getAptReservations(state) {
      return state.aptReservations;
    },
    getDeviceInfo(state) {
      return state.aptReservations.deviceInfo;
    },
    getUTMParams(state) {
      return state.aptReservations.utmParams;
    },
    // ... otros getters que puedas necesitar
  };
  
  export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
  };